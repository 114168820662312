import Popup from 'component/common/Popup';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import ProfileAvtar from 'component/common/ProfileAvtar';
import LoaderComp from 'component/loader/LoaderComp';
import { assignRoleForAccessPopup, searchAllUser } from 'redux/appThunk/Admin/permission';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'hooks/useDebounce';
import { RxCross1 } from 'react-icons/rx';
import { CiSearch } from 'react-icons/ci';

const AddAccessMembers = ({
  userDrop,
  setUserDrop,
  roleName,
  setAgainData,
}) => {
  const [searchItem, setSearchItem] = useState('');
  const [loder, setLoder] = useState(false);
  const [submitOnce, setSubmitOnce] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const searchQuery = useDebounce(searchItem, 500);
  const { allUsers } = useSelector((state) => state.employeeReducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchAllUser(searchItem, setLoder));
  }, [searchQuery]);

  const handleSelectUser = (data) => {
    setUsersData((prevUsersData) => {
      if (prevUsersData.includes(data)) {
        return prevUsersData.filter((user) => user !== data);
      } else {
        return [...prevUsersData, data];
      }
    });
  };

  const handleSelectAllUser = () => {
    setUsersData((prevUsersData) => {
      if (allUsers.every(user => prevUsersData.includes(user))) {
        return prevUsersData.filter(user => !allUsers.includes(user));
      } else {
        return [...new Set([...prevUsersData, ...allUsers])];
      }
    });
  };

  const handleRoleSave = async () => {
    if (!usersData?.length) return;
    setSubmitOnce(true);
    await dispatch(assignRoleForAccessPopup(usersData?.map(item => item?.accountId), roleName, setAgainData));
    await setSearchItem('');
    await setSubmitOnce(false);
    await setUserDrop(false);
    await setUsersData([]);
  };

  const handleCancel = () => {
    setUserDrop(false);
    setUsersData([]);
    setSearchItem('');
  };

  return (
    <div >
      {userDrop && (
        <Popup
          popupBox={handleCancel}
          title={t("addMember")}
          handleSubmit={handleRoleSave}
          submitBtnText="add"
          maxWidth='max-w-[45rem]'
          submitOnce={submitOnce}
        >
          <div className='flex flex-col gap-4'>
            <div className='h-full max-h-[7rem] overflow-y-auto custom_scroll'>
              <div className='w-full flex gap-3 flex-wrap'>
                {usersData?.map((items) => {
                  return (
                    <div key={items} className='bg-[#031b59] h-[2rem] w-fit flex gap-4 text-white items-center px-3 rounded-[1rem] '>
                      <h1 className='capitalize'>{items?.name}</h1>
                      <div className='cursor-pointer' onClick={() => handleSelectUser(items)}>
                        <RxCross1 />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='relative'>
              <input
                className='w-full rounded-2xl p-3 border'
                placeholder='Enter Employee Name....'
                onChange={(e) => setSearchItem(e.target.value)}
              />
              <div className='text-[1.5rem] absolute top-3 right-3'>
                <CiSearch />
              </div>
            </div>
            <div className=' h-[55vh] overflow-y-auto custom_scroll'>
              <table className="w-full p-3 bg-white rounded-lg text-left relative">
                <thead className="flex-0 text-left z-10 left-0 sticky top-0">
                  <tr
                    className="max-h-[3.125rem] p-2 text-[#686868]
                      text-sm  font-normal bg-[#F2F6FF] border-collapse left-0 z-30"
                  >
                    <th
                      className="lg:w-[18.224rem] min-w-[18.224rem]
                        max-h-[3.125rem] h-full px-2 py-4 bg-[#F2F6FF] flex gap-3 items-center"
                    >
                      <div className='flex items-center'>
                        <input
                          className=" cursor-pointer h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
                        accent-[#031B59!important] checked:bg-[#031B59!important]
                        checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                        checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                        checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                        checked:after:rotate-[45deg]
                        checked:after:border-[0.15rem] checked:after:border-l-0
                        checked:after:border-t-0 checked:after:border-solid checked:after:border-white
                        checked:after:bg-transparent checked:after:content-['']" type="checkbox"
                          onChange={handleSelectAllUser}
                          checked={allUsers?.length > 0 && (usersData?.length === allUsers?.length)}
                        />
                      </div>
                      {t("employeeName")}
                    </th>
                    <th
                      className="lg:w-[14rem] min-w-[9rem] max-h-[3.125rem] h-full p-2  bg-[#F2F6FF] "
                    >
                      {t("designation")}
                    </th>
                  </tr>
                </thead>
                {allUsers?.length > 0 ? (<>
                  {!loder ?
                    <tbody className="p-2 text-sm text-left font-normal flex-0 w-full ">
                      <>
                        {allUsers?.map((data, index) => {
                          return (
                            <Fragment key={`permission-${index}`}>
                              <tr key={`resource-row-${index}`} className='h-[50px] even:bg-[#F8FAFC]'
                                onChange={() => handleSelectUser(data)}
                              >
                                <td className='lg:w-[18.224rem] min-w-[18.224rem]
                                   max-h-[3.125rem] h-full px-2 py-4 capitalize'>
                                  <div className='h-full flex gap-3 items-center'>
                                    <div className='flex items-center'>
                                      <input
                                        className=" cursor-pointer h-6 w-6 self-center appearance-none
                                         rounded-lg text-[#191919] border
                                         accent-[#031B59!important] checked:bg-[#031B59!important]
                                         checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                                         checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                                         checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                                         checked:after:rotate-[45deg]
                                         checked:after:border-[0.15rem] checked:after:border-l-0
                                         checked:after:border-t-0 checked:after:border-solid checked:after:border-white
                                         checked:after:bg-transparent checked:after:content-['']" type="checkbox"
                                        checked={(usersData?.map(item => item?.id))?.includes(data?.id)}
                                      />
                                    </div>
                                    <div className='flex items-center gap-2'>
                                      <ProfileAvtar
                                        height="2rem"
                                        width="2rem"
                                        name={data?.name ? data?.name : "User"}
                                      />
                                      <div>
                                        <h1 className='text-[1.1rem] font-medium text-[#031b59]'>{data?.name ? data?.name : "User"}</h1>
                                        <span className='text-[#A1A1A1]'>{data?.empCode ? data?.empCode : "---"}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className='w-full lg:w-[18.224rem] min-w-[18.224rem]
                                    max-h-[3.125rem] h-full px-2 capitalize '>
                                  <div className='w-full flex items-center justify-between'>
                                    <p className='font-normal text-[1rem] text-[#031b59] py-4'>{data?.designation ? data?.designation : '---'}</p>
                                    {data?.lastRole && (<span className={`py-1 px-2 rounded-3xl ${data?.lastRole === "employee" ?
                                      "bg-[#FEF1E7] text-[#ED6E0F]" :
                                      `${data?.lastRole === "admin" ? "bg-[#F2F6FF] text-[#031b59]" : `${data?.lastRole === "client" ?
                                        "bg-[#E7EEFF] text-[#6B95FB]" : `${"bg-[#afafe7] text-[#1c3c88]"
                                        }`
                                      }`}`}`}>
                                      {data?.lastRole}
                                    </span>)}
                                  </div>
                                </td>
                              </tr>
                            </Fragment>
                          );
                        }
                        )}
                      </>
                    </tbody>
                    :
                    <div className='absolute top-16 right-[50%]'>
                      <LoaderComp />
                    </div>
                  }
                </>) : (
                  <div
                    className="w-full flex items-center
             justify-center font-medium mt-5 ml-32"
                  >
                    {t("no_data_found")}
                  </div>
                )}
              </table>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default AddAccessMembers;

AddAccessMembers.propTypes = {
  setUserDrop: PropTypes.func,
  userDrop: PropTypes.bool,
  roleName: PropTypes.string,
  setAgainData: PropTypes.func,
};
