import React from "react";
import PropTypes from "prop-types";
import { Svg18 } from "svgComponents/Svg";
import { useSelector } from "react-redux";
import { content } from "utils/constant/Content";
import DatePicker from "component/common/DatePicker";
import { useTranslation } from "react-i18next";
import { employeeTypeObj } from "component/common/enum";
import LoaderComp from "component/loader/LoaderComp";

export default function PersonalInfoForm({
  editState,
  setEditState,
  handle,
  setState,
  setCountry,
  bloodGroup,
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  handleBlur,
  handleKeyPress,
  initialValues,
  setFieldValue,
}) {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const { countryname, statename, cityname } = useSelector(
    (state) => state.CompanyProfileReducer
  );

  return (
    <div>
      <div>
        <form>
          <div
            className={`${
              userData?.role === employeeTypeObj.admin
                ? "h-fit w-full overflow-y-scroll m-[0.8rem] custom_scroll p-3 bg-[#f2f6ff]"
                : "w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-1 gap-4"
            }`}
          >
            <div
              className={`${
                userData?.role === employeeTypeObj.admin
                  ? "grid grid-cols-2 gap-2"
                  : "grid grid-cols-2  w-full gap-x-2 gap-y-2"
              }`}
            >
              <div>
                <label
                  htmlFor="full_name"
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("fullName")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      type="text"
                      name="fullName"
                      id="fullName"
                      value={values?.fullName}
                      maxLength={50}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    ></input>
                    {errors?.fullName && touched?.fullName ? (
                      <p className="text-[red]">{errors?.fullName}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.fullName
                      ? editState
                        ? values?.fullName
                        : initialValues?.fullName
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("fathersName")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      data-testid="fname"
                      type="text"
                      name="fatherName"
                      id="father_name"
                      maxLength={50}
                      value={values?.fatherName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors?.fatherName && touched?.fatherName ? (
                      <p className="text-[red]">{errors?.fatherName}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.fatherName
                      ? editState
                        ? values?.fatherName
                        : initialValues.fatherName
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("mothersName")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      type="text"
                      name="motherName"
                      data-testid="mname"
                      id="mother_name"
                      maxLength={50}
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      value={values?.motherName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors?.motherName && touched?.motherName ? (
                      <p className="text-[red]">{errors?.motherName}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.motherName
                      ? editState
                        ? values?.motherName
                        : initialValues.motherName
                      : "N/A"}
                  </h1>
                )}
              </div>

              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("gender")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <div className="relative">
                      <select
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                        id="gender"
                        name="gender"
                        data-testid="Gender"
                        value={values?.gender}
                        onChange={handleChange}
                      >
                        <option value={""}>Select Gender</option>
                        <option value={content.female}>{content.female}</option>
                        <option value={content.male}>{content.male}</option>
                        <option value={content.others}>{content.others}</option>
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                    {errors?.gender && touched?.gender ? (
                      <p className="text-[red]">{errors?.gender}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.gender
                      ? editState
                        ? values?.gender
                        : initialValues.gender
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("dOB")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <div className="">
                      <DatePicker
                        name="dateOfBirth"
                        data-testid="DateOfBirth"
                        value={values?.dateOfBirth}
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        styles="h-11"
                      />
                    </div>
                    {errors?.dateOfBirth && touched?.dateOfBirth ? (
                      <p className="text-[red]">{errors?.dateOfBirth}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.dateOfBirth
                      ? editState
                        ? values?.dateOfBirth
                        : initialValues.dateOfBirth
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div className="">
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("maritalStatus")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <div className="relative">
                      <select
                        id="marital_status"
                        className="h-[2.625rem] w-full  p-2 border border-[#E2E8F0]
            bg-white rounded text-[#191919] appearance-none"
                        type="text"
                        name="maritalStatus"
                        data-testid="Mstatus"
                        value={values?.maritalStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Marital status</option>
                        <option value="married">Married</option>
                        <option value="unmarried">Unmarried</option>
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                    {errors?.maritalStatus && touched?.maritalStatus ? (
                      <p className="text-[red]">{errors.maritalStatus}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] capitalize p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.maritalStatus
                      ? editState
                        ? values?.maritalStatus
                        : initialValues.maritalStatus
                      : "N/A"}
                  </h1>
                )}
              </div>

              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("bloodGroup")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <div className="relative">
                      <select
                        className="h-[2.625rem] w-full  p-2 border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                        type="text"
                        id="bloodGroup"
                        data-testid="Blood"
                        value={values?.bloodGroup}
                        onChange={(e) => {
                          setFieldValue("bloodGroup", e.target.value);
                        }}
                      >
                        <option value={""}>Select Blood Group</option>
                        {bloodGroup?.length > 0 &&
                          bloodGroup?.map((bg, index) => (
                            <option value={bg} key={index}>
                              {bg}
                            </option>
                          ))}
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                    {errors?.bloodGroup && touched?.bloodGroup ? (
                      <p className="text-[red]">{errors?.bloodGroup}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.bloodGroup
                      ? editState
                        ? values?.bloodGroup
                        : initialValues.bloodGroup
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("personalEmail")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      type="text"
                      id="personalEmail"
                      name="personalEmail"
                      data-testid="Pemail"
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      value={values?.personalEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors?.personalEmail && touched?.personalEmail ? (
                      <p className="text-[red]">{errors?.personalEmail}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.personalEmail
                      ? editState
                        ? values?.personalEmail
                        : initialValues.personalEmail
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("officalEmail")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      name="email"
                      type="text"
                      data-testid="OEmail"
                      id="email"
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      value={values?.email}
                      onChange={handleChange}
                      disabled
                      onBlur={handleBlur}
                    />
                    {errors?.personalEmail && touched?.personalEmail ? (
                      <p className="text-[red]">{errors.personalEmail}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.email
                      ? editState
                        ? values?.email
                        : initialValues.email
                      : "N/A"}
                  </h1>
                )}
              </div>

              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("contactNo")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      type="text"
                      id="contact_no"
                      name="contactNo"
                      data-testid="ContactNo"
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      value={values?.contactNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength="10"
                      max="10"
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors?.contactNo && touched?.contactNo ? (
                      <p className="text-[red]">{errors?.contactNo}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.contactNo
                      ? editState
                        ? values?.contactNo
                        : initialValues.contactNo
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div className="">
                <label
                  htmlFor="emergency_contact_no"
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("emergencycontactNo")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div>
                    <input
                      type="text"
                      id="emergency_contact_no"
                      name="emergencyContactNo"
                      data-testid="EmergencyContactNo"
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      value={values?.emergencyContactNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength="10"
                      max="10"
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors?.emergencyContactNo && touched?.emergencyContactNo ? (
                      <p className="text-[red]">{errors?.emergencyContactNo}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.emergencyContactNo
                      ? editState
                        ? values?.emergencyContactNo
                        : initialValues.emergencyContactNo
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("address")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      name="address"
                      type="text"
                      id="address"
                      data-testid="Address"
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      value={values?.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors?.address && touched?.address ? (
                      <p className="text-[red]">{errors?.address}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full overflow-hidden border border-slate-200 rounded-[4px]">
                    {values?.address
                      ? editState
                        ? values?.address
                        : initialValues.address
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("country")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <div className="relative">
                      <select
                        name="country"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                        type="text"
                        id="country"
                        data-testid="Country"
                        value={values?.country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setFieldValue("country", e.target.value);
                        }}
                      >
                        <option>Select Country</option>
                        {countryname &&
                          Object.keys(countryname)?.map((st, index) => (
                            <option value={st} key={index}>
                              {countryname[st]}
                            </option>
                          ))}
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                      {errors?.country && touched?.country ? (
                        <p className="text-[red]">{errors?.country}</p>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.country
                      ? editState
                        ? values?.country
                        : initialValues.country
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("state")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <div className="relative">
                      <select
                        name="state"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                        type="text"
                        data-testid="State"
                        id="state"
                        value={values?.state}
                        onChange={(e) => {
                          setState(e.target.value);
                          setFieldValue("state", e.target.value);
                        }}
                      >
                        <option>Select State</option>
                        {statename &&
                          Object.keys(statename)?.map((st, index) => (
                            <option value={st} key={index}>
                              {statename[st]}
                            </option>
                          ))}
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                      {errors?.state && touched?.state ? (
                        <p className="text-[red]">{errors.state}</p>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.state
                      ? editState
                        ? values?.state
                        : initialValues.state
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("city")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <div className="relative">
                      <select
                        name="city"
                        data-testid="City"
                        className="h-[2.625rem] w-full  p-2 border border-[#E2E8F0]
              bg-white rounded text-[#191919] appearance-none"
                        type="text"
                        id="city"
                        value={values?.city}
                        onChange={(e) => {
                          setFieldValue("city", e.target.value);
                        }}
                      >
                        <option>Select City</option>
                        {cityname?.length > 0 &&
                          cityname?.map((cty, index) => (
                            <option key={index} value={cty}>
                              {cty}
                            </option>
                          ))}
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                    {errors?.city && touched?.city ? (
                      <p className="text-[red]">{errors?.city}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.city
                      ? editState
                        ? values?.city
                        : initialValues?.city
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("pinCode")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      type="text"
                      id="pincode"
                      name="pincode"
                      data-testid="Pincode"
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      value={values?.pincode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength="6"
                      max="6"
                      onKeyPress={handleKeyPress}
                    />
                    {errors?.pincode && touched?.pincode ? (
                      <p className="text-[red]">{errors?.pincode}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.pincode
                      ? editState
                        ? values?.pincode
                        : initialValues.pincode
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label
                  className={`${
                    userData?.role === employeeTypeObj.admin
                      ? "text-[0.8rem] text-[grey]"
                      : "text-[#313135]"
                  }`}
                >
                  {t("linkedInUrl")}
                </label>
                {editState === 1 || userData?.role === employeeTypeObj.employee ? (
                  <div className="">
                    <input
                      type="url"
                      id="linkedin_profile"
                      name="linkedinProfile"
                      data-testid="linkedin_profile"
                      className={`${
                        userData?.role === employeeTypeObj.admin
                          ? "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                          : "h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      }`}
                      value={values?.linkedinProfile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full text-ellipsis overflow-hidden border border-slate-200 rounded-[4px]">
                    {values?.linkedinProfile
                      ? editState
                        ? values?.linkedinProfile
                        : initialValues?.linkedinProfile
                      : "N/A"}
                  </h1>
                )}
              </div>
            </div>

            {editState === 1 && (
              <>
                {handle ? (
                  <div className="flex items-center p-2 pt-[1rem] justify-end">
                    <LoaderComp className="h-0.5 w-0.5 bg-black" />
                  </div>
                ) : (
                  <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end">
                    <button
                      data-testid="buttons"
                      className="border border-[#031b59] p-2 w-20 rounded-3xl"
                      onClick={() => setEditState(false)}
                    >
                      {t("cancel")}
                    </button>
                    <button
                      className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
                      onClick={handleSubmit}
                    >
                      {t("save")}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

PersonalInfoForm.propTypes = {
  editState: PropTypes.bool.isRequired,
  setEditState: PropTypes.func.isRequired,
  handle: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  bloodGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  accordionOpen: PropTypes.any,
  clickEye: PropTypes.any,
};
