import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import swalService from "../../utils/SwalServices";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { personalInformationSchema } from "redux/validator/Profile/personalInfo";
import LoaderComp from "component/loader/LoaderComp";
import {
  PersonalProgress,
  Profile,
  getCities,
  profileData,
  updateUserProfile
} from "redux/appThunk/Employee/profile";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  handleCountryName,
  handleStateChange,
  handlecitychange
} from "redux/appThunk/Admin/companyProfile";
import PersonalInfoForm from "component/common/EmployeeForm/PersonalInfoForm";

export default function PersonalInformation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cityFind, setcityFind] = useState("");
  const [loading, setLoading] = useState(false);
  const [handle, setHandle] = useState(false);
  const [submitshow, setSubmitshow] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [setHandelValue] = useState(false);
  // const [setTwoNameCountry] = useState('');
  const [, setReload] = useState(false);
  const { id } = useParams();
  const user = useSelector((state) => state.profileReducer.profileData);

  const Progress = useSelector((state) => state.profileReducer.personalStatus);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  useEffect(() => {
    dispatch(handleCountryName());
    dispatch(handleStateChange(country));
    dispatch(handlecitychange(state));
  }, [country, state]);

  useEffect(() => {
    if (id) {
      // dispatch(handleCountryName());
      dispatch(profileData(id, setLoading));
      dispatch(Profile(id));
      dispatch(PersonalProgress(id));
    } else {
      // dispatch(handleCountryName());
      dispatch(Profile(userData?.id));
      dispatch(profileData(userData?.id, setLoading));
      dispatch(PersonalProgress(userData?.id));
    }
  }, [userData?.token, handle]);

  const initialValues = {
    email: user?.email,
    fullName: user?.full_name,
    fatherName: user?.father_name,
    motherName: user?.mother_name,
    linkedinProfile: user?.linkedin_profile,
    contactNo: user?.contact_no,
    personalEmail: user?.personal_email,
    blood_group: user?.blood_group,
    maritalStatus: user?.marital_status,
    dateOfBirth: user?.date_of_birth,
    gender: user?.gender,
    city: user?.city,
    state: user?.state,
    country: user?.country,
    pincode: user?.pincode,
    address: user?.address,
    emergencyContactNo: user?.emergency_contact_no,
  };

  const Formik = useFormik({
    enableReinitialize: true,
    validationSchema: personalInformationSchema,
    initialValues: initialValues,
    onSubmit: async (values) => {
      const jsonObj = {
        user: {
          email: values?.email,
          full_name: values?.fullName,
          father_name: values?.fatherName,
          mother_name: values?.motherName,
          linkedin_profile: values?.linkedinProfile,
          contact_no: values?.contactNo,
          personal_email: values?.personalEmail,
          blood_group: values?.blood_group,
          marital_status: values?.maritalStatus,
          date_of_birth: values?.dateOfBirth,
          gender: values?.gender,
          country: values?.country,
          city: values?.city,
          state: values?.state,
          pincode: values?.pincode,
          address: values?.address,
          emergency_contact_no: values?.emergencyContactNo,
        },
      };
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update Details!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            let id;
            if (userData?.role === "admin") {
              id = user?.id;
            } else if (userData?.role === "employee") {
              id = userData?.id;
            }
            dispatch(
              updateUserProfile(
                id,
                jsonObj,
                setHandle,
                setSubmitshow,
                setReload
              )
            );
          }
        });
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = Formik;

  useEffect(() => {
    dispatch(getCities(cityFind, values?.state));
  }, [cityFind, values?.state]);

  useEffect(() => {
    setcityFind(user?.state);
  }, [user]);

  const bloodGroup = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];

  function handleOnChange(e) {
    handleChange(e);
    setSubmitshow(true);
    setHandelValue(true);

    if (e.target.name === "blood_group") {
      setFieldValue("blood_group", e.target.value);
    } else if (e.target.name === "state") {
      setcityFind(e.target.value);
      setFieldValue("state", e.target.value);
    } else if (e.target.name === "city") {
      setFieldValue("city", e.target.value);
    }
  }

  function handleCountryChange(e) {
    setCountry(e.target.value);
    setFieldValue("country", e.target.value);
    setSubmitshow(true);
  }

  function handleState(e) {
    setState(e.target.value);
    setFieldValue("state", e.target.value);
    setSubmitshow(true);
  }

  function handleCity(e) {
    setFieldValue("city", e.target.value);
    setSubmitshow(true);
  }

  // const handleClickChange = (val) => {
  //   setTwoNameCountry(val);
  // };
  useEffect(() => {
    setCountry(user?.country);
    setState(user?.state);
  }, [user]);

  return (
    <div className="w-[96%] h-[fit] p-5 bg-white flex-flex-col space-y-8 rounded-[20px]">
      {!loading ? (
        <>
          <div className="w-full h-[6rem] p-2 bg-[#F2F6FF] flex items-center gap-6 shadow-lg rounded-md">
            <div className="w-[5rem] h-[5rem] flex justify-center items-center">
              <CircularProgressbar
                value={Progress}
                text={Progress + "%"}
                styles={buildStyles({
                  rotation: 1.0,
                  strokeLinecap: "butt",
                  textSize: "22px",
                  pathColor: `#031B59`,
                  textColor: "#031B59",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                  counterClockwise: false,
                  strokeWidth: "90px",
                })}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="capitalize text-[#031B59]">
                {Progress === 100 ? (
                  <h1>{t("progress1")}</h1>
                ) : (
                  <h1>{t("progress2")}</h1>
                )}
              </div>
              <div>
                {Progress !== 100 ? (
                  <h2 className="text-[#686868]">{t("progressBarText")}</h2>
                ) : null}
              </div>
            </div>
          </div>
          <PersonalInfoForm
            handleChange={handleOnChange}
            handleCity={handleCity}
            handleState={handleState}
            handleCountryChange={handleCountryChange}
            erros={errors}
            handleBlur={handleBlur}
            touched={touched}
            setLoading={setLoading}
            bloodGroup={bloodGroup}
            setCountry={setCountry}
            setFieldValue={setFieldValue}
            setState={setState}
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            values={values}
          />
        </>
      ) : (
        <LoaderComp />
      )}
      {submitshow ? (
        <div className="w-full h-16 bg-white flex justify-end">
          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full"
              onClick={() => {
                setSubmitshow(false);
                setHandelValue(false);
              }}
            >
              {t("cancel")}
            </button>
            <button
              className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("save")}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

PersonalInformation.propTypes = {
  isEdit: PropTypes.any,
};
