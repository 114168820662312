import ToastServices from "ToastServices";
import Helper from "api/Helper";
import {
  getAllRoles,
  setPageCount,
  isLoading,
  getAllPermissions,
  setPermissionsByRoleId,
  allusers,
  setUserByPermission,
  setPermissionRoles,
  emptyPermission,
  permissionAction
} from "redux/actions/action";
import {
  allEmptyPermission,
  formatPermissionData,
  formatRoleData,
  formatUsers,
  formateUserList,
  retreivePermissionData
} from "redux/selector/Admin/permission";

export const fetchRoleDataa =
  (itemsPerPage, currentPage, searchQuery, setLoading) => async (dispatch) => {
    try {
      setLoading(true);
      const path = `/list_roles?&page=${currentPage}&per_page=${itemsPerPage}&query=${searchQuery}`;
      const { response } = await Helper.get(path);
      const formattedResponse = formatRoleData(
        response?.roles_with_users_count
      );
      dispatch(getAllRoles(formattedResponse));
      dispatch(setPageCount(response?.pagination?.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToastServices.showToast({
        message: "Something went wrong !",
        type: "warning",
        autoClose: 3000,
      });
    }
  };

export const fetchRoleDataWithoutPage = () => async (dispatch) => {
  try {
    const path = `/list_roles`;
    const { response } = await Helper.get(path);
    const formattedResponse = formatRoleData(response?.roles_with_users_count);
    dispatch(getAllRoles(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
  }
};

export const assignRoleForAccessPopup = (Ids, roleName, setAgainData) => async () => {
  try {
    setAgainData(false);
    const path = `users/assign_role?account_ids=${Ids}&name=${roleName}`;
    const { status } = await Helper.post({}, path);
    if (status === 200 || status === 201) {
      setAgainData(true);
      ToastServices.showToast({
        message: "Something went wrong !",
        type: "warning",
        autoClose: 1000,
      });
    }
  }
  catch (err) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 1000,
    });
  }
};

export const AddNewRoleWithPerm = (jsonObj, setSubmitOn) => async () => {
  try {
    setSubmitOn(true);
    const path = `roles/permissions`;
    const { status } = await Helper.post(jsonObj, path);
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Added user Successfully !",
        type: "success",
        autoClose: 3000,
      });
      setSubmitOn(false);
    }
  } catch (err) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 1000,
    });
  }
};

export const roleDelete =
  (id, setSubmitOn, setRoleDeletePop, setAgainData) => async (dispatch) => {
    try {
      setSubmitOn(true);
      const path = `users/${id}/delete_role`;
      const { status } = await Helper.delete(path, id);
      if (status === 200 || status === 201) {
        ToastServices.showToast({
          message: "User Remove Successfully !",
          type: "success",
          autoClose: 3000,
        });
        setSubmitOn(false);
        setRoleDeletePop(false);
        dispatch(fetchRoleDataa(10, 1, "", setAgainData));
      }
    } catch (err) {
      ToastServices.showToast({
        message: "Something went wrong !",
        type: "warning",
        autoClose: 2000,
      });
      setSubmitOn(false);
    }
  };

export const assignRoleForAccessPopupNew = (Ids, roleName) => async () => {
  try {
    const paths = `users/assign_role?user_ids=${Ids}&name=${roleName}`;
    const { status } = await Helper.post({}, paths);
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Added user Successfully !",
        type: "success",
        autoClose: 1000,
      });
    }
  } catch (err) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 1000,
    });
  }
};

export const assignRoleToAUser =
  (selectedRole, roleName, isChange, change, handleClose) =>
    async (dispatch) => {
      try {
        dispatch(isLoading(true));
        const path = `users/assign_role?user_ids=${selectedRole}&name=${roleName}`;
        const { status } = await Helper.post({}, path);
        if (status === 200 || status === 201) {
          handleClose();
          ToastServices.showToast({
            message: "Updated Successfull !",
            type: "success",
            autoClose: 1000,
          });
          setTimeout(() => {
            isChange(!change);
          }, 1000);
          dispatch(isLoading(false));
        }
      } catch (error) {
        ToastServices.showToast({
          message: "Something went wrong !",
          type: "warning",
          autoClose: 3000,
        });
      }
    };

export const fetchALLPermissions = () => async (dispatch) => {
  const path = `permissions`;
  const { response } = await Helper.get(path);
  const formattedResponse = formatPermissionData(response);
  dispatch(getAllPermissions(formattedResponse));
};

export const fetchPermissionsByRoleId = (id) => async (dispatch) => {
  try {
    const path = `roles/${id}/permissions`;
    const { response } = await Helper.get(path);
    const formattedResponse = retreivePermissionData(response);
    dispatch(setPermissionsByRoleId(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: "Role not found",
      type: error,
      autoClose: 3000,
    });
  }
};

export const permissionApi = (name, setShimer) => async (dispatch) => {
  try {
    setShimer(true);
    const path = `roles/,/permissions/?name=${name}`;
    const { response, status } = await Helper.get(path);
    const formattedResponse = retreivePermissionData(response ? response : {});
    dispatch(permissionAction(formattedResponse?.requiredResponse));
    if (status === 200 || status === 201) {
      setShimer(false);
    }
  } catch (error) {
    setShimer(false);
    ToastServices.showToast({
      message: "Role not found",
      type: error,
      autoClose: 3000,
    });
  }
};

export const fetchRolesPermId = (id) => async (dispatch) => {
  try {
    const path = `roles/${id}/permissions`;
    const { response } = await Helper.get(path);
    const formattedResponse = retreivePermissionData(response);
    dispatch(setPermissionRoles(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: "Role not found",
      type: error,
      autoClose: 3000,
    });
  }
};

export const showUsersOfSpecificRole = (role) => async (dispatch) => {
  try {
    const path = `users_by_role?role=${role}`;
    const { response } = await Helper.get(path);
    const formateUserListData = formateUserList(response?.users);
    dispatch(setUserByPermission(formateUserListData));
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
  }
};

export const searchAllUser = (searchQuery, setLoder) => async (dispatch) => {
  try {
    setLoder(true);
    dispatch(isLoading(true));
    const path = `users/search?query=${searchQuery}`;
    const { response, status } = await Helper.get(path);
    const formateUserListData = formatUsers(response?.users);
    dispatch(allusers(formateUserListData));
    if (status === 200 || status === 201) {
      setLoder(false);
    }
  } catch (error) {
    setLoder(false);
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
  }
};

export const UpdatePermmisions = (json, id, setSubmitOn) => async () => {
  try {
    setSubmitOn(true);
    const path = `/permissions/update_role_and_permissions/${id}`;
    const response = await Helper.patch(json, path);
    if (response.status === 200 || response?.status === 201) {
      ToastServices.showToast({
        message: "Updated Permissions Successfully!",
        type: "success",
        autoClose: 3000,
      });
      setSubmitOn(false);
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
    setSubmitOn(false);
  }
};

export const EmptyPermission = () => async (dispatch) => {
  try {
    const path = `/permissions`;
    const { response } = await Helper.get(path);
    const formatResp = allEmptyPermission(response ? response : {});
    dispatch(emptyPermission(formatResp));
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
  }
};
