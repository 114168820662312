import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { personalInformationSchema } from "redux/validator/Profile/personalInfo";
import swalService from "utils/SwalServices";
import {
  profileData,
  updateUserProfile
} from "redux/appThunk/Employee/profile";
import { employeeTypeObj } from "component/common/enum";
import {
  handlecitychange,
  handleCountryName,
  handleStateChange
} from "redux/appThunk/Admin/companyProfile";
import PersonalInfoForm from "component/common/EmployeeForm/PersonalInfoForm";

export default function PersonalInfo({
  accordionOpen,
  editState,
  setEditState,
  clickEye,
}) {

  const dispatch = useDispatch();
  const [handle, setHandle] = useState(false);
  const user = useSelector((state) => state.profileReducer.profileData);
  const bloodGroup = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  useEffect(() => {
    dispatch(handleCountryName());
  }, []);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  useEffect(() => {
    dispatch(handleStateChange(country));
  }, [country]);
  useEffect(() => {
    dispatch(handlecitychange(state));
  }, [state]);
  useEffect(() => {
    dispatch(profileData(clickEye?.id, setHandle));
  }, [editState]);

  const initialValues = {
    email: user?.email,
    fullName: user?.full_name,
    fatherName: user?.father_name,
    motherName: user?.mother_name,
    linkedinProfile: user?.linkedin_profile,
    contactNo: user?.contact_no,
    personalEmail: user?.personal_email,
    blood_group: user?.blood_group,
    maritalStatus: user?.marital_status,
    dateOfBirth: user?.date_of_birth,
    gender: user?.gender,
    country: user?.country,
    city: user?.city,
    state: user?.state,
    pincode: user?.pincode,
    address: user?.address,
    emergencyContactNo: user?.emergency_contact_no,
  };

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: personalInformationSchema,
    onSubmit: async (values) => {
      const jsonObj = {
        user: {
          email: values?.email,
          full_name: values?.fullName,
          father_name: values?.fatherName,
          mother_name: values?.motherName,
          linkedin_profile: values?.linkedinProfile,
          contact_no: values?.contactNo,
          personal_email: values?.personalEmail,
          blood_group: values?.blood_group,
          marital_status: values?.maritalStatus,
          date_of_birth: values?.dateOfBirth,
          gender: values?.gender,
          country: values?.country,
          city: values?.city,
          state: values?.state,
          pincode: values?.pincode,
          address: values?.address,
          emergency_contact_no: values?.emergencyContactNo,
        },
      };

      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update Details!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            let id;
            if (userData?.role === employeeTypeObj.admin) {
              id = user?.id;
            }
            if (userData?.role === employeeTypeObj.employee) {
              id = userData?.id;
            }
            dispatch(
              updateUserProfile(
                id,
                jsonObj,
                setHandle,
                setEditState,
                setEditState
              )
            );
          }
        });
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = Formik;

  const handleKeyPress = (e) => {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className='max-w-[54rem]'>
        {accordionOpen === 1 && (
          <PersonalInfoForm
            setHandle={setHandle}
            handle={handle}
            country={country}
            setState={setState}
            setCountry={setCountry}
            bloodGroup={bloodGroup}
            values={values}
            errors={errors}
            touched={touched}
            state={state}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleBlur={handleBlur}
            handleKeyPress={handleKeyPress}
            setFieldValue={setFieldValue}
            editState={editState}
            initialValues={initialValues}
          />
        )}
      </div>
    </div>
  );
}

PersonalInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
  clickEye: PropTypes.any,
};
