import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllManager,
  profileData,
  updateRestrictedUserProfile
} from "redux/appThunk/Employee/profile";
import { fetchDesignations } from "redux/appThunk/Admin/department";
import { Svg18 } from "svgComponents/Svg";
import { useTranslation } from "react-i18next";
import swalService from "utils/SwalServices";
import { useFormik } from "formik";
import DatePicker from "component/common/DatePicker";
import LoaderComp from "component/loader/LoaderComp";
import * as Yup from "yup";
import ProfileAvtar from "component/common/ProfileAvtar";
// import usePermissions, { mappedPermissionObj } from "hooks/usePermission";

export default function OtherInfo({
  accordionOpen,
  editState,
  setEditState,
  eyeId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.profileReducer.profileData);
  const isLoading = useSelector((state) => state.profileReducer.isLoading);
  const [handle, setHandle] = useState(false);
  const [show, setShow] = useState(false);
  const userslist = useSelector((state) => state.profileReducer.usersList);
  // const { userPermission } = usePermissions(mappedPermissionObj.User);

  useEffect(() => {
    dispatch(profileData(eyeId, setLoading));
    dispatch(fetchDesignations());
    dispatch(fetchAllManager());
  }, [eyeId,accordionOpen === 2]);

  const section = {
    section_192: "Section_192",
    section_194: "Section_194",
  };

  useEffect(() => {
    if (isLoading) {
      dispatch(profileData(eyeId, setLoading));
    }
  }, [isLoading,accordionOpen === 2]);

  const designationState = useSelector(
    (state) => state.designationReducer.allDesignation
  );

  const initialValues = {
    profile_url: user?.profile_url,
    id: user?.id,
    employeeType: user?.employee_type && user?.employee_type,
    uanNo: user?.uan_no,
    esicNo: user?.esic_no,
    designation: user?.designation?.designation,
    designationId: user?.designation?.id,
    dateOfJoining: user?.date_of_joining,
    relievingDate: user?.relieving_date,
    jobType: user?.job_type ? user?.job_type : t("work_from_home"),
    resignationDate: user?.resignation_date,
    resignationStatus: user?.resignation_status,
    noticePeriod: user?.notice_period,
    retentionBonus: user?.retention_bonus,
    retentionTime: user?.retention_time,
    retentionBonusNo: user?.retention_bonus_no,
    sectionApplicable: user?.section_applicable,
    managerId: user?.manager_id,
    incomeTax: user?.income_tax,
    managerName: user?.manager_name,
  };

  const validationSchema = Yup.object({
    noticePeriod: Yup.number()
      .typeError("Notice period must be a number")
      .min(1, "Notice period must be at least 1")
      .max(999, "Notice period cannot exceed 3 digit")
      .nullable(true),
  });

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let jsonObj = {
        user: {
          uan_no: values?.uanNo,
          esic_no: values?.esicNo,
          id: values?.id,
          employee_type: values?.employeeType,
          job_type: values?.jobType,
          date_of_joining: values?.dateOfJoining,
          relieving_date: values?.relievingDate,
          resignation_date: values?.resignationDate,
          resignation_status: values?.resignationStatus,
          notice_period: values?.noticePeriod,
          retention_bonus: values?.retentionBonus,
          retention_time: values?.retentionTime,
          retention_bonus_no: values?.retentionBonusNo,
          designation_id: values?.designationId,
          section_applicable: values?.sectionApplicable,
          manager_id: Number(values?.managerId),
          manager_name: values?.managerName,
          income_tax: values?.incomeTax,
        },
      };

      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            const id = user?.id;

            dispatch(
              updateRestrictedUserProfile(
                id,
                jsonObj,
                setHandle,
                setEditState,
                setShow
              )
            );
          }
        });
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
  } = Formik;

  const getResignationStatusTextColor = (resignationStatus) => {
    if (resignationStatus === "Approved") {
      return "text-[#1A8718]";
    } else if (resignationStatus === "Cancelled") {
      return "text-[#FF0000]";
    } else {
      return "text-[#191919]";
    }
  };

  return (
    <div>
      <div>
        {accordionOpen === 2 && !loading && (
          <div className="h-fit w-[51rem] overflow-y-scroll custom_scroll m-[0.8rem] p-3 bg-[#f2f6ff]">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label className="text-[0.8rem] text-[grey] ">
                  {t("employee_id")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <input
                      type="text"
                      name="id"
                      disabled
                      id="id"
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                        bg-white rounded text-[#191919] appearance-none"
                      value={values.id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values.id
                      ? editState || show
                        ? values.id
                        : initialValues.id
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div className="">
                <label className="text-[0.8rem] text-[grey]">
                  {t("employeeType")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div className="relative">
                      <select
                        name="employeeType"
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                          bg-white rounded text-[#191919] appearance-none"
                        type="number"
                        id="employeeType"
                        disabled={!editState}
                        value={values.employeeType}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue(
                            "section_applicable",
                            e.target.value === "contract"
                              ? section.section194
                              : values.sectionApplicable
                          );
                        }}
                        onBlur={handleBlur}
                      >
                        <option>{t("select")}</option>
                        <option value="Full Time">{t("fullTime")}</option>
                        <option value="Contract">{t("contract")}</option>
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1 className="text-[1rem] capitalize p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.employeeType
                      ? editState || show
                        ? values?.employeeType
                        : initialValues.employeeType
                      : "N/A"}
                  </h1>
                )}
              </div>

              <div>
                <div className="flex flex-col space-y-2">
                  <label className="text-[0.8rem] text-[grey]">
                    {t("sectionApplicable")}
                  </label>
                  {editState === 2 ? (
                    <div className="relative">
                      <select
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                          bg-white rounded text-[#191919] appearance-none"
                        name="sectionApplicable"
                        type="number"
                        id="secApplicable"
                        value={values.sectionApplicable}
                        disabled={!editState}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {Object.entries(section).map(([key, label]) => (
                          <option key={key} value={key} className="capitalize">
                            {t(label)}
                          </option>
                        ))}
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                  ) : (
                    <h1 className="text-[1rem] capitalize p-1 w-full border border-slate-200 rounded-[4px]">
                      {values?.sectionApplicable
                        ? initialValues.sectionApplicable
                        : "N/A"}
                    </h1>
                  )}
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="uAN" className="text-[0.8rem] text-[grey]">
                  {t("uAN")}
                </label>
                {editState === 2 ? (
                  <input
                    type="text"
                    name="uanNo"
                    id="uAN"
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                      bg-white rounded text-[#191919] appearance-none"
                    disabled={!editState}
                    value={values.uanNo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    max="12"
                    maxLength="12"
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        e.preventDefault();
                      }
                    }}
                  />
                ) : (
                  <h1 className="text-[1rem] capitalize p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.uanNo ? initialValues.uanNo : "N/A"}
                  </h1>
                )}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="empSIC" className="text-[0.8rem] text-[grey]">
                  {t("empSIC")}
                </label>
                {editState === 2 ? (
                  <input
                    type="text"
                    name="esicNo"
                    id="empSIC"
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                      bg-white rounded text-[#191919] appearance-none"
                    value={values.esicNo}
                    disabled={!editState}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={25}
                  />
                ) : (
                  <h1 className="text-[1rem] capitalize p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.esicNo ? initialValues.esicNo : "N/A"}
                  </h1>
                )}
              </div>

              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("designations")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div className="relative">
                      <select
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                          bg-white rounded text-[#191919] appearance-none"
                        type="text"
                        name="designationId"
                        id="designation"
                        value={values?.designationId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!editState}
                      >
                        <option value="">{t("select")}</option>
                        {Array.isArray(designationState) &&
                          designationState
                            .filter((item) => item && item.designation !== null)
                            .map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.designation}
                              </option>
                            ))}
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                    {errors.designation && touched.designation ? (
                      <p className="text-[red]">{errors.designation}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.designation ? values?.designation : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("jobType")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div className="relative">
                      <select
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                          bg-white rounded text-[#191919] appearance-none"
                        type="number"
                        name="jobType"
                        id="jobType"
                        value={values.jobType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!editState}
                      >
                        <option value="Work from Office">
                          {t("work_from_office")}
                        </option>
                        <option value="Remote">{t("Remote")}</option>
                        <option value="Hybrid">{t("Hybrid")}</option>
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.jobType
                      ? editState || show
                        ? values?.jobType
                        : initialValues.jobType
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("d_O_J")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div>
                      <DatePicker
                        name="dateOfJoining"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.dateOfJoining}
                        disabled={!editState}
                        styles="11"
                      />
                    </div>
                    {errors.dateOfJoining && touched.dateOfJoining ? (
                      <p className="text-[red]">{errors.dateOfJoining}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.dateOfJoining
                      ? editState || show
                        ? values?.dateOfJoining
                        : initialValues.dateOfJoining
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("relievingDate")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div>
                      <DatePicker
                        name="relievingDate"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.relievingDate}
                        disabled={!editState}
                        styles="11"
                      />
                    </div>
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.relievingDate
                      ? editState || show
                        ? values?.relievingDate
                        : initialValues.relievingDate
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("resiDate")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div>
                      <DatePicker
                        name="resignationDate"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.resignationDate}
                        disabled={!editState}
                        styles="11"
                      />
                    </div>
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.resignationDate
                      ? editState || show
                        ? values?.resignationDate
                        : initialValues.resignationDate
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("resiStatus")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div className="relative">
                      <select
                        className={`h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                          bg-white appearance-none rounded
                          ${getResignationStatusTextColor(
                    values.resignationStatus
                  )}`}
                        name="resignationStatus"
                        id="resiStatus"
                        value={values.resignationStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!editState}
                      >
                        <option value="Pending" className="text-[#191919]">
                          {t("pending")}
                        </option>
                        <option value="Approved" className="text-[#1A8718]">
                          {t("approved")}
                        </option>
                        <option value="Cancelled" className="text-[#FF0000]">
                          {t("cancelled")}
                        </option>
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1 className={`text-[1rem] p-1 w-full border border-slate-200 rounded-[4px] 
                    ${getResignationStatusTextColor(values?.resignationStatus || initialValues.resignationStatus)}`}>
                    {values?.resignationStatus
                      ? editState || show
                        ? values?.resignationStatus
                        : initialValues.resignationStatus
                      : "N/A"}
                  </h1>
                )}
              </div>

              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("noticePeriod")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <input
                      type="text"
                      name="noticePeriod"
                      id="noticePeriod"
                      value={values.noticePeriod}
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                        bg-white rounded text-[#191919] appearance-none"
                      disabled={!editState}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.noticePeriod
                      ? editState || show
                        ? values?.noticePeriod
                        : initialValues.noticePeriod
                      : "N/A"}
                  </h1>
                )}
                {errors.noticePeriod && touched.noticePeriod ? (
                  <p className="text-[red]">{errors.noticePeriod}</p>
                ) : null}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("retentionTimePeriod")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div className="relative">
                      <select
                        type="text"
                        name="retentionTime"
                        id="retenTimePeriod"
                        value={values.retentionTime}
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                          rounded text-[#191919] bg-white appearance-none"
                        disabled={!editState}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>{t("select")}</option>
                        <option className="accent-[#031B59]" value="Monthly">
                          {t("monthly")}
                        </option>
                        <option className="accent-[#031B59]" value="Quarterly">
                          {t("quarterly")}
                        </option>
                        <option
                          className="accent-[#031B59]"
                          value="Half Yearly"
                        >
                          {t("halfYearly")}
                        </option>
                        <option className="accent-[#031B59]" value="Yearly">
                          {t("yearly")}
                        </option>
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.retentionTime
                      ? editState || show
                        ? values?.retentionTime
                        : initialValues.retentionTime
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("numOfRetentionBonus")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <input
                      type="text"
                      name="retentionBonusNo"
                      id="numOfRetenBonus"
                      value={values.retentionBonusNo}
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                        bg-white rounded text-[#191919] appearance-none"
                      disabled={!editState}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={20}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.retentionBonusNo && touched.retentionBonusNo ? (
                      <p className="text-[red]">{errors.retentionBonusNo}</p>
                    ) : null}
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.retentionBonusNo
                      ? editState || show
                        ? values?.retentionBonusNo
                        : initialValues.retentionBonusNo
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("incomeTax")}
                </label>
                {editState === 2 ? (
                  <div className="">
                    <div className="relative">
                      <select
                        className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                  bg-white rounded text-[#191919] appearance-none"
                        type="number"
                        name="incomeTax"
                        id="incometax"
                        value={values.incomeTax === "new_regime" ? "New Regime" : "Old Regime"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!editState}
                      >
                        <option value="">select</option>
                        <option value="New Regime">{t("newregime")}</option>
                        <option value="Old Regime">{t("oldRegime")}</option>
                      </select>
                      <div className="absolute right-0 top-0 mt-3">
                        <Svg18 />
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1 className="text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                    {values?.incomeTax
                      ? editState || show
                        ? values?.incomeTax
                        : initialValues.incomeTax === "new_regime" ? "New Regime" : "Old Regime"
                      : "N/A"}
                  </h1>
                )}
              </div>
              <div>
                <label className="text-[0.8rem] text-[grey]">
                  {t("reportingManager")}
                </label>
                <div className="flex items-center gap-2">
                  {values?.profile_url ? (
                    <div>
                      <img
                        src={
                          values?.managerId
                            ? userslist
                              ?.filter(
                                (item) => item.id === values?.managerId
                              )
                              .map((item) => item.profile_url)
                            : ""
                        }
                        alt="scription"
                        className="w-12 h-12 rounded-full mr-4"
                      />
                    </div>
                  ) : (
                    <ProfileAvtar
                      name={values?.managerName || values?.name || "M"}
                      bgImgColor="#031B59"
                      height="3rem"
                      width="3rem"
                    />
                  )}
                  {editState === 2 ? (
                    <div className="flex-none">
                      <div className="relative">
                        <select
                          className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                            bg-white rounded text-[#191919] appearance-none"
                          type="number"
                          name="managerId"
                          id="managerId"
                          value={values.managerId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!editState}
                        >
                          <option value={"Select"}>{"Select"}</option>
                          {userslist?.map((item) => (
                            <option key={item?.id} value={item?.id}>
                              {item.full_name ? item.full_name : "N/A"}
                            </option>
                          ))}
                        </select>
                        <div className="absolute right-0 top-0 mt-3">
                          <Svg18 />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h1 className="text-[1rem]">
                      {values?.managerId
                        ? editState || show
                          ? values?.managerId &&
                            userslist
                              ?.filter((item) => item.id === values?.managerId)
                              .map((item) => item.full_name)
                          : initialValues.managerId &&
                            userslist
                              ?.filter(
                                (item) => item.id === initialValues?.managerId
                              )
                              .map((item) => item.full_name)
                        : "N/A"}
                    </h1>
                  )}
                </div>
              </div>
            </div>
            {/* {userPermission?.update && ( */}
            <>
              {editState === 2 && (
                <>
                  {handle ? (
                    <div className="flex items-center p-2 pt-[1rem] justify-end">
                      <LoaderComp className="h-2 w-2 size-2 bg-black" />
                    </div>
                  ) : (
                    <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end">
                      <button
                        className="border border-[#031b59] p-2 w-20 rounded-3xl"
                        onClick={() => setEditState(false)}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {t("save")}
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
}

OtherInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
  clickEye: PropTypes.any,
  eyeId: PropTypes.any,
};
